'use strict';

angular.module('enervexSalesappApp').config(function($stateProvider) {
	$stateProvider
		.state('main', {
			url: '/',
			templateUrl: 'app/main/main.html',
			controller: 'MainCtrl'
		})
		.state('invitation', {
			url: '/accounts/:accountId/jobs/:jobId/invitations/:token',
			templateUrl: 'app/main/invitations/invitation.html',
			controller: 'InvitationCtrl',
			authenticate: false
		})
		.state('account-invitation', {
			url: '/accounts/:accountId/invitations/:token',
			templateUrl: 'app/main/invitations/invitation.html',
			controller: 'InvitationCtrl',
			authenticate: false
		})
		.state('products', {
			url: '/products',
			templateUrl: 'app/main/products/products.html',
			controller: 'ProductsCtrl'
		})
		.state('product', {
			url: '/products/:id',
			templateUrl: 'app/main/products/product.html',
			controller: 'ProductCtrl'
		})
		.state('accounts', {
			url: '/accounts',
			templateUrl: 'app/main/accounts/accounts.html',
			controller: 'AccountsCtrl'
		})
		.state('updates', {
			url: '/updates',
			templateUrl: 'app/main/accounts/updates.html',
			controller: 'UpdatesCtrl'
		})
		.state('global-proposals', {
			url: '/proposals',
			templateUrl: 'app/main/global-documents/global-documents.html',
			controller: 'GlobalDocumentsCtrl'
		})
		.state('global-jobs', {
			url: '/jobs/:term',
			templateUrl: 'app/main/global-jobs/global-jobs.html',
			controller: 'GlobalJobsCtrl'
		})
		.state('account-requests', {
			url: '/account-requests',
			templateUrl: 'app/main/account-requests/account-requests.html',
			controller: 'AccountRequestsCtrl'
		})
		.state('account-request', {
			url: '/account-requests/:accountRequestId',
			templateUrl: 'app/main/account-requests/account-request.html',
			controller: 'AccountRequestCtrl'
		})
		.state('account-membership-request', {
			url: '/account-membership-requests/:accountMembershipRequestId',
			templateUrl: 'app/main/account-membership-requests/account-membership-request.html',
			controller: 'AccountMembershipRequestCtrl'
		})
		.state('account', {
			url: '/accounts/:accountId',
			templateUrl: 'app/main/accounts/account.html',
			controller: 'AccountCtrl'
		})
		.state('ventbom-projects', {
			url: '/accounts/:accountId/ventbom-projects',
			templateUrl: 'app/main/ventbom-projects/ventbom-projects.html',
			controller: 'VentbomProjectsCtrl'
		})
		.state('account-details', {
			url: '/accounts/:accountId/details',
			templateUrl: 'app/main/accounts/account.edit.html',
			controller: 'AccountEditCtrl'
		})
		.state('account-invite', {
			url: '/accounts/:accountId/invites',
			templateUrl: 'app/main/accounts/account-invite.html',
			controller: 'AccountInviteCtrl'
		})
		.state('contacts', {
			url: '/accounts/:accountId/contacts',
			templateUrl: 'app/main/contacts/contacts.html',
			controller: 'ContactsCtrl'
		})
		.state('companies', {
			url: '/accounts/:accountId/companies',
			templateUrl: 'app/main/companies/companies.html',
			controller: 'CompaniesCtrl'
		})
		.state('company', {
			url: '/accounts/:accountId/companies/:companyId',
			templateUrl: 'app/main/companies/company.html',
			controller: 'CompanyCtrl'
		})
		.state('account-products', {
			url: '/accounts/:accountId/products',
			templateUrl: 'app/main/products/products.html',
			controller: 'ProductsCtrl'
		})
		.state('account-product', {
			url: '/accounts/:accountId/products/:id',
			templateUrl: 'app/main/products/product.html',
			controller: 'ProductCtrl'
		})
		.state('contact', {
			url: '/accounts/:accountId/contacts/:contactId',
			templateUrl: 'app/main/contacts/contact.html',
			controller: 'ContactCtrl'
		})
		.state('proposals', {
			url: '/accounts/:accountId/jobs/:jobId/proposals',
			templateUrl: 'app/main/documents/documents.html',
			controller: 'DocumentsCtrl'
		})
		.state('proposal', {
			url: '/accounts/:accountId/jobs/:jobId/proposals/:documentId?mode&version&quoteId&print',
			templateUrl: 'app/main/documents/document.html',
			controller: 'DocumentCtrl'
		})
		.state('account-locations', {
			url: '/accounts/:accountId/account-locations',
			templateUrl: 'app/main/account-locations/account-locations.html',
			controller: 'AccountLocationsCtrl'
		})
		.state('account-location', {
			url: '/accounts/:accountId/account-locations/:accountLocationId',
			templateUrl: 'app/main/account-locations/account-location.html',
			controller: 'AccountLocationCtrl'
		})
		.state('fancalc-jobs', {
			url: '/accounts/:accountId/account-locations/:accountLocationId/fancalc-jobs',
			templateUrl: 'app/main/fancalc-jobs/fancalc-jobs.html',
			controller: 'FancalcJobsCtrl'
		})
		.state('fancalc-jobs-account', {
			url: '/accounts/:accountId/fancalc-jobs',
			templateUrl: 'app/main/fancalc-jobs/fancalc-jobs.html',
			controller: 'FancalcJobsCtrl'
		})
		.state('account-territories', {
			url: '/accounts/:accountId/account-locations/:accountLocationId/account-territories',
			templateUrl: 'app/main/account-territories/account-territories.html',
			controller: 'AccountTerritoriesCtrl'
		})
		.state('account-territory-requests', {
			url: '/accounts/:accountId/account-locations/:accountLocationId/account-territory-requests',
			templateUrl: 'app/main/account-territory-requests/account-territory-requests.html',
			controller: 'AccountTerritoryRequestsCtrl'
		})
		.state('account-territory-request', {
			url: '/accounts/:accountId/account-locations/:accountLocationId/account-territory-requests/:accountTerritoryRequestId',
			templateUrl: 'app/main/account-territory-requests/account-territory-request.html',
			controller: 'AccountTerritoryRequestCtrl'
		})
		.state('account-members', {
			url: '/accounts/:accountId/members',
			templateUrl: 'app/main/accounts/account-detail.html',
			controller: 'AccountDetailCtrl'
		})
		.state('jobs', {
			url: '/accounts/:accountId/jobs',
			templateUrl: 'app/main/jobs/jobs.html',
			controller: 'JobsCtrl'
		})
		.state('job', {
			url: '/accounts/:accountId/jobs/:jobId',
			templateUrl: 'app/main/jobs/job.html',
			controller: 'JobDetailCtrl'
		})
		.state('job-details', {
			url: '/accounts/:accountId/jobs/:jobId/details',
			templateUrl: 'app/main/jobs/job.edit.html',
			controller: 'JobEditCtrl'
		})
		.state('job-invites', {
			url: '/accounts/:accountId/jobs/:jobId/job-invites',
			templateUrl: 'app/main/jobs/job.invite.html',
			controller: 'JobInvitesCtrl'
		})
		.state('attachments', {
			url: '/accounts/:accountId/jobs/:jobId/attachments',
			templateUrl: 'app/main/attachments/attachments.html',
			controller: 'AttachmentsCtrl'
		})
		.state('attachment', {
			url: '/accounts/:accountId/jobs/:jobId/attachments/:attachmentId?mode&version',
			templateUrl: 'app/main/attachments/attachment.html',
			controller: 'AttachmentCtrl'
		})
		.state('discussions', {
			url: '/accounts/:accountId/jobs/:jobId/discussions',
			templateUrl: 'app/main/discussions/discussions.html',
			controller: 'DiscussionsCtrl'
		})
		.state('discussion', {
			url: '/accounts/:accountId/jobs/:jobId/discussions/:discussionId?mode',
			templateUrl: 'app/main/discussions/discussion.html',
			controller: 'DiscussionCtrl'
		})
		.state('designs', {
			url: '/accounts/:accountId/jobs/:jobId/designs',
			templateUrl: 'app/main/designs/designs.html',
			controller: 'DesignsCtrl'
		})
		.state('design', {
			url: '/accounts/:accountId/jobs/:jobId/designs/:designId?version&copyDesignId&copyVersion&mode&dirty',
			templateUrl: 'app/main/designs/design.html',
			controller: 'DesignCtrl'
		})
		.state('specifications', {
			url: '/accounts/:accountId/jobs/:jobId/specifications',
			templateUrl: 'app/main/specifications/specifications.html',
			controller: 'SpecificationsCtrl'
		})
		.state('specification', {
			url: '/accounts/:accountId/jobs/:jobId/specifications/:specificationId?mode&version',
			templateUrl: 'app/main/specifications/specification.html',
			controller: 'SpecificationCtrl'
		})
		.state('drawing', {
			url: '/accounts/:accountId/jobs/:jobId/designs/:designId/drawing?version',
			templateUrl: 'app/main/drawings/drawing.html',
			controller: 'DrawingCtrl'
		})
		.state('quotes', {
			url: '/accounts/:accountId/jobs/:jobId/quotes',
			templateUrl: 'app/main/quotes/quotes.html',
			controller: 'QuotesCtrl'
		})
		.state('quote', {
			url: '/accounts/:accountId/jobs/:jobId/quotes/:quoteId?mode&version&designId',
			templateUrl: 'app/main/quotes/quote.html',
			controller: 'QuoteCtrl'
		})
		.state('todolists', {
			url: '/accounts/:accountId/jobs/:jobId/todolists',
			templateUrl: 'app/main/todolists/todolists.html',
			controller: 'TodolistsCtrl'
		})
		.state('todolist', {
			url: '/accounts/:accountId/jobs/:jobId/todolists/:todolistId?mode',
			templateUrl: 'app/main/todolists/todolist.html',
			controller: 'TodolistCtrl'
		})
		.state('todo', {
			url: '/accounts/:accountId/jobs/:jobId/todolists/:todolistId/todos/:todoId?mode',
			templateUrl: 'app/main/todos/todo.html',
			controller: 'TodoCtrl'
		});
});
